<template>
 <div class="input-with-desc d-flex">
     <div class="w-25 flex-fill bd-highlight">{{ agentName }}</div>
     <div class="w-25 pos-rel flex-fill bd-highlight mright-15">
        <input type="text" class="form-control" v-bind:value="agentName=='Alek Packard'? '#C5DEF5' : color" v-on:click="colpicker1 = !colpicker1">
         <div v-show="colpicker1" class="colorpicker">
             <div class="Popover-message Popover-message--top-left p-2 mt-2 Box color-shadow-large">
               <p class="f6 color-fg-muted mb-1">Choose from default colors:</p>
               <div class="d-flex flex-justify-between mb-2" >
                   <button v-for="pcolor in palatecolors" type="button" class="new-label-color-dimensions btn-link rounded-1"  v-bind:style="{ backgroundColor: pcolor}" v-on:click = "updateColor(e,pcolor)"></button>
               </div>
               <div class="d-flex flex-justify-between">
                  <button v-for="pcolor in palatecolors2" type="button" class="new-label-color-dimensions btn-link rounded-1"  v-bind:style="{ backgroundColor: pcolor}" v-on:click = "updateColor(e,pcolor)"></button>
               </div>
              </div>
          </div>
     </div>
     <div class="w-25 pos-rel flex-fill bd-highlight mright-15">
         <input type="text" class="form-control"  v-bind:value="agentName=='Alek Packard'? '#666666' : tcolor" v-on:click="colpicker2 = !colpicker2">
         <div v-show="colpicker2" class="colorpicker">
             <div class="Popover-message Popover-message--top-left p-2 mt-2 Box color-shadow-large">
               <p class="f6 color-fg-muted mb-1">Choose from default colors:</p>
               <div class="d-flex flex-justify-between mb-2" >
                   <button v-for="pcolor in palatecolors" type="button" class="new-label-color-dimensions btn-link rounded-1"  v-bind:style="{ backgroundColor: pcolor}" v-on:click = "updateTextColor(e,pcolor)"></button>
               </div>
               <div class="d-flex flex-justify-between">
                  <button v-for="pcolor in palatecolors3" type="button" class="new-label-color-dimensions btn-link rounded-1"  v-bind:style="{ backgroundColor: pcolor}" v-if=" agentName == 'Alek Packard'?(tcolor='#666666') : tcolor " v-on:click = "updateTextColor(e,pcolor)"></button>
               </div>
              </div>
          </div>
     </div>
     <div class="w-25 text-center pt-1 mright-5 flex-fill bd-highlight" :style="{ backgroundColor: color, color: tcolor }" v-if=" agentName == 'Alek Packard'?(color='#C5DEF5') : color "  v-on:click="selectFromColorIndex">{{ agentName }}</div>
 </div>
</template>

<script>
import { ColorPicker } from "vue-accessible-color-picker";
var pickerClick = 0;
const color1 = ["#81B5D4", "#F1948A", "#F7DC6F" , "#5AD6F5","#00C0EF","#00A7D0", "#5A6E83","#001F3F","#001A35", "#7FDEDE","#39CCCC","#30BBBB", "#5AC594","#00A65A","#008D4C", "#9896C7","#605CA8","#555299","#F7BF66","#F39C12","DB8B0B","#FFB06B","#FF851B","#FF7701","#E98B7F","#DD4B39","#D33724","#E66B98","#D81B60","#CA195A","#E2E4EA","#D2D6DE","#B5BBC8","#656565","#111111","#000000","#e0e0e0",];

var colorindex = 0;
export default {
name: 'Agent Color Codes Config',
props: {
    agentName: String,
  },
components: {
 ColorPicker
},
data: function(){
   return{
     segment3 : false,
     segment4 : false,
     segment5 : false,
     segment12 : false,
     showColorCodesDetails : false,
     value: true,
     active_el : '',
     colEmail : false,
     createNewColorCodes:false,
     showAlertColorCodes:false,
      color: '#e0e0e0',
      tcolor: '#000000',
      colpicker1:false,
      colpicker2:false,
      colorindex:0
   }
      
},
methods: {
 toggleSegmentCollapsedStateDahboard(i) {
   this['segment'+i] = !this['segment'+i] 
 },
 showMessageDashboardDetails(type,row) {
   if(previousrowVal == row){
       this.showColorCodesDetails = !this.showColorCodesDetails;
       if(!this.showColorCodesDetails){
         this.active_el = '';
       }else{
         this.active_el = row;  
       }
      
   }else{
      this.showColorCodesDetails = this.showColorCodesDetails;
         this.active_el = row;     
   }
   previousrowVal = row;
   
   this.showColorCodesDetails = true;
   this.createNewColorCodes = false;
   
   //this.emitter.emit("show-message-details", {displayType:type, showColorCodesDetails : this.showColorCodesDetails});
   
 },
 updateColor (event, pcolor) {
   this.color = pcolor;
   this.colpicker1 = false;
   event.preventDefault;
 },
 updateTextColor (event, tcolor) {
   this.tcolor = tcolor;
   this.colpicker2 = false;
   event.preventDefault;
 },
 selectFromColorIndex(e){
     console.log(this.colorindex);
     if(this.colorindex == color1.length){
       this.colorindex = 0;
     }
     this.color = color1[this.colorindex];
     this.colpicker1 = false;
    this.colorindex++;
 },
 selectTextFromColorIndex(e){
     console.log(this.colorindex);
     if(this.colorindex == color2.length){
       this.colorindex = 0;
     }
     this.textcolor = color2[this.colorindex];
     this.colpicker2 = false;
    this.colorindex++;
 }
},mounted() {
 
},
setup() {
     // make users variable reactive with the ref() function
  // const palatecolors = ['#edf5e0','#F7DC6F',"#F1948A",'#FBCA04','#daf2b0','#b7e4ff','#fadefc','#e1f99d','#e6ccfc'];
     const palatecolors = ['#edf5e0','#e0e0e0','#C5DEF5','#BFD4F2','#e6ccfc','#D4C5F9','#b7e4ff','#a2dbdb','#BFDADC'];
     
     const palatecolors2 = ['#e1f99d','#daf2b0','#FEF2C0','#F7DC6F','#FBCA04','#f7e0dc','#F9D0C4','#E99695','#F1948A' ];
    
     const palatecolors3 = ['#ffffff','#cccccc','#666666','#888888','#000000','#f7e0dc','#F9D0C4','#E99695','#F1948A' ];

     return {
         palatecolors,palatecolors2,palatecolors3
     };
 }
}

</script>