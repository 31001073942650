<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-paint-brush"></i>
    <h3 class="box-title text-right">Skills Configuration</h3>
  </div>
  <div class="box-body">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group has-error">
        <label class="control-label text-left">Skill Name</label>
        <input id="name" class="is-invalid form-control" type="text">
        <div class="invalid-feedback">
          <span>Skill Name is required</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group bg-group">
        <label class="control-label text-left">Interaction Property Based Skills</label>
        <div class="">
          <div class="group-bg group-input detail-g-borderbottom">
            <label class="col-md-12 control-label text-left group-input-label">Interaction Property</label>
            <div class="col-md-12">
               <Multiselect
                    v-model="value"
                    mode="tags"
                    placeholder="Select or search property in list"
                    label="name"
                    :groups="true"
                    :searchable="true"
                    :options="[
                        {
                        label: 'Customer Success',
                        options:  [
                            { value: 'Archived', name: 'Archived',level:'1'},
                            { value: 'Bounce', name: 'Bounce',level:'1'},
                            
                        ],
                        }
                    ]"
                    >
                    <template v-slot:option="{ option }">
                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                    </template>
                </Multiselect>
            </div>
          </div>
          <div class="group-bg group-input detail-g-bordertop">
            <label class="col-md-12 control-label text-left group-input-label">Interaction Property Value</label>
            <div class="col-md-12">
              <input type="text" class="form-control group-input-item">
              <!--v-if-->
              <!--v-if-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <div class="row">
    <div class="col-md-2 mt-3">
      <button id="Create" class="btn btn-primary margin-r-5">Save</button>
    </div>
  </div>
  <div class="row">
    <div class="clearfix"></div>
    <div class="col-md-4">
        <div class="nested-panel-heading skill-heading">
            <h3 class="nested-title">Agents That Have This Skill </h3>
            <span class=" mleft-5 p-1" v-on:click="showSkillConfiguration = !showSkillConfiguration " style="background-color: rgb(162, 219, 219);"><i class="fa fa-gear"></i></span>
        </div>
        <div class="skill-list">
            <span class="skill-item">
              <span class="fa fa-close text-red mright-5"></span>
              <a href="" class="agentSkillColor agentSkillColorDefault" style="background: #C5DEF5; color: #666666;">Alek Packard (alek.packard@example.com)</a>
            </span>
            <span class="skill-item">
              <span class="fa fa-close text-red mright-5"></span>
              <a href="" class="agentSkillColor agentSkillColorDefault" style="background: #f7e0dc; color: #666666;">Jacob Smith (jack.smith@example.com)</a>
              
            </span>
            <span class="skill-item">
              <span class="fa fa-close text-red mright-5"></span>
              <a href="" class="agentSkillColor agentSkillColorDefault">Justin Time (justin.time@example.com)</a>
              
            </span>
            <span class="skill-item">
              <span class="fa fa-close text-red mright-5"></span>
              <a href="" class="agentSkillColor agentSkillColorDefault">Robert Charleston (robert@example.com)</a>
            </span>
        </div>
    </div>

    <div class="col-md-4">
        <div class="nested-panel-heading skill-heading">
            <h3 class="nested-title">Topics That Require This Skill</h3>
        </div>
        <div class="skill-list">
            <div class="list-headings">Customer Service Segment</div>
            <div class="segment-item">
            <span class="skill-item">
              <span class="fa fa-close text-red mright-5"></span>
                <a href="">All Topics</a>
                <a href="">_Spam</a> 
            </span>
            </div>
            <div class="list-headings">Sales Segment</div>
            <div class="segment-item">
            <span class="skill-item">
                <span class="fa fa-close text-red mright-5"></span>
                <a href="">All Topics</a>
                <a href="">Follow Ups</a>
                
            </span>
            </div>
            <div class="segment-item">
            <span class="skill-item">
              <span class="fa fa-close text-red mright-5"></span>
                <a href="">All Topics</a>
                <a href="">Sales Prospecting</a>   
            </span>
            </div>
        </div>
        </div>
  </div>

  <div class="row" v-if="showSkillConfiguration">
    <div class="col-md-12">
      <div class="box box-info with-border">
        <div class="box-header with-border"><i class="fa fa-color"></i><h3 class="box-title text-right">Color Configuration for Agents with Skills : No Route</h3>
          <button id="close" class="btn btn-link margin-r-5 pull-right" v-on:click="showSkillConfiguration = false">Close</button>
        </div>
        <div class="box-body">   
            <div class="simple-label multiple pos-rel">
                  <div class="input-with-desc d-flex">
                    <div class="flex-fill bd-highlight">
                      <label>Agent Name</label>
                    </div>
                    <div class="flex-fill bd-highlight">
                      <label class="pleft-5">Background Color</label>
                    </div>
                    <div class="flex-fill bd-highlight">
                      <label class="pleft-5">Font Color</label>
                    </div>
                    <div class="flex-fill bd-highlight">
                      <label class="pleft-5">Display Example</label>
                    </div>
                </div>
                <div class="mbottom-15" v-for="(val, index) of optionparameter">
                    <AgentColorInstance :agentName="val"></AgentColorInstance>
                </div>
            </div>
        </div>
      </div>
     
    </div>
  </div>

 
  <div class="row">
    <div class="col-md-10 mt-3 admin-error">
      <!--v-if-->
    </div>
  </div>
</div>
</div>
</template>

<script>
import { ColorPicker } from "vue-accessible-color-picker";
import AgentColorInstance from '../components/AgentColorInstance.vue';

var previousrowVal;
export default {
  name: 'Skill Config',
  components: {
    ColorPicker,
    AgentColorInstance
  },
  data: function(){
      return{
        segment3 : false,
        segment4 : false,
        segment5 : false,
        segment12 : false,
        showColorCodesDetails : false,
        value: true,
        active_el : '',
        colEmail : false,
        createNewColorCodes:false,
        showAlertColorCodes:false,
         color: '#e0e0e0',
         colpicker1:false,
          optionparameter : ['Alek Packard','Jacob Smith','Justin Time','Robert Charleston'],
          searchlength : 1,
          spliton : false,
          showerror:false,
          showSkillConfiguration:false
      }
         
  },
   methods: {
    toggleSegmentCollapsedStateDahboard(i) {
      this['segment'+i] = !this['segment'+i] 
    },
    showMessageDashboardDetails(type,row) {
      if(previousrowVal == row){
          this.showColorCodesDetails = !this.showColorCodesDetails;
          if(!this.showColorCodesDetails){
            this.active_el = '';
          }else{
            this.active_el = row;  
          }
         
      }else{
         this.showColorCodesDetails = this.showColorCodesDetails;
            this.active_el = row;     
      }
      previousrowVal = row;
      
      this.showColorCodesDetails = true;
      this.createNewColorCodes = false;
      
      //this.emitter.emit("show-message-details", {displayType:type, showColorCodesDetails : this.showColorCodesDetails});
      
    },
    updateColor (eventData) {
      this.color = eventData.cssColor
    },
    addValue() {
      this.optionparameter.push('')
      this.searchlength = parseInt(this.searchlength) + 1;
   },
   removeValue(index) {
      if(this.optionparameter.length > 1){
       this.optionparameter.splice(index, 1);
       this.searchlength = parseInt(this.searchlength) - 1;
      }
    },
    splitGroup(){
       this.spliton = true
    }
  }
}

</script>