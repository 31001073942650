<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-paint-brush"></i>
    <h3 class="box-title text-right">Skills Configuration</h3>
  </div>
  <div class="box-body">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group has-error">
        <label class="control-label text-left">Skill Name</label>
        <input id="name" class="is-invalid form-control" type="text">
        <div class="invalid-feedback">
          <span>Skill Name is required</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group bg-group">
        <label class="control-label text-left">Interaction Property Based Skills</label>
        <div class="">
          <div class="group-bg group-input detail-g-borderbottom">
            <label class="col-md-12 control-label text-left group-input-label">Interaction Property</label>
            <div class="col-md-12">
               <Multiselect
                    v-model="value"
                    mode="tags"
                    placeholder="Select or search property in list"
                    label="name"
                    :groups="true"
                    :searchable="true"
                    :options="[
                        {
                        label: 'Customer Success',
                        options:  [
                            { value: 'Archived', name: 'Archived',level:'1'},
                            { value: 'Bounce', name: 'Bounce',level:'1'},
                            
                        ],
                        }
                    ]"
                    >
                    <template v-slot:option="{ option }">
                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                    </template>
                </Multiselect>
            </div>
          </div>
          <div class="group-bg group-input detail-g-bordertop">
            <label class="col-md-12 control-label text-left group-input-label">Interaction Property Value</label>
            <div class="col-md-12">
              <input type="text" class="form-control group-input-item">
              <!--v-if-->
              <!--v-if-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 mt-3">
      <button id="Create" class="btn btn-primary margin-r-5">Create</button>
      <button id="Cancel" class="btn btn-link">Cancel</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 mt-3 admin-error">
      <!--v-if-->
    </div>
  </div>
</div>
</div>

   
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import SkillsDetail from '../components/SkillsDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Skills Settings',
   components: {
    Breadcrumb,
    iServiceSwitch,
    Multiselect,
    Toggle,
    Multiselect,
    SkillsDetail
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showSkillsDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showSkillsDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showSkillsDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showSkillsDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
